:root {
	--rmdp-primary-blue: #1671e4;
	--rmdp-secondary-blue: #1468d1;
	--rmdp-shadow-blue: #1051a3;
	--rmdp-today-blue: #8bb9f4;
	--rmdp-hover-blue: #1051a3;
	--rmdp-deselect-blue: #1051a3;
}

.blue .rmdp-wrapper {
	border: 1px solid var(--rmdp-secondary-blue);
	box-shadow: 0 0 5px var(--rmdp-secondary-blue);
}

.blue .rmdp-panel-body li {
	background-color: var(--rmdp-primary-blue);
	box-shadow: 0 0 2px var(--rmdp-secondary-blue);
}

.blue .rmdp-week-day {
	color: var(--rmdp-primary-blue);
}

.blue .rmdp-day.rmdp-deactive {
	color: var(--rmdp-secondary-blue);
}

.blue .rmdp-range {
	background-color: var(--rmdp-primary-blue);
	box-shadow: 0 0 3px var(--rmdp-shadow-blue);
}

.blue .rmdp-arrow {
	border: solid var(--rmdp-primary-blue);
	border-width: 0 2px 2px 0;
}

.blue .rmdp-arrow-container:hover {
	background-color: var(--rmdp-primary-blue);
	box-shadow: 0 0 3px var(--rmdp-secondary-blue);
}

.blue .rmdp-panel-body::-webkit-scrollbar-thumb {
	background: var(--rmdp-primary-blue);
}

.blue .rmdp-day.rmdp-today span {
	background-color: var(--rmdp-today-blue);
}

.blue .rmdp-rtl .rmdp-panel {
	border-left: unset;
	border-right: 1px solid var(--rmdp-secondary-blue);
}

.blue .rmdp-day.rmdp-selected span:not(.highlight) {
	background-color: var(--rmdp-primary-blue);
	box-shadow: 0 0 3px var(--rmdp-shadow-blue);
}

.blue .rmdp-day:not(.rmdp-day-hidden) span:hover {
	background-color: var(--rmdp-hover-blue) !important;
}

.blue .b-deselect {
	color: var(--rmdp-deselect-blue);
	background-color: white;
}

.blue .rmdp-action-button {
	color: var(--rmdp-primary-blue);
}

.blue .rmdp-button:not(.rmdp-action-button) {
	background-color: var(--rmdp-primary-blue);
}

.blue .rmdp-button:not(.rmdp-action-button):hover {
	background-color: var(--rmdp-deselect-blue);
}
