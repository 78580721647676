.advanced-cropper-line-wrapper--north,
.advanced-cropper-line-wrapper--south {
	height: 12px;
	width: 100%;
}

.advanced-cropper-line-wrapper--north {
	cursor: n-resize;
}

.advanced-cropper-line-wrapper--south {
	cursor: s-resize;
}

.advanced-cropper-line-wrapper--east,
.advanced-cropper-line-wrapper--west {
	width: 12px;
	height: 100%;
}

.advanced-cropper-line-wrapper--east {
	cursor: e-resize;
}

.advanced-cropper-line-wrapper--west {
	cursor: w-resize;
}

.advanced-cropper-line-wrapper--disabled {
	cursor: auto;
}

.advanced-cropper-line-wrapper__content {
	position: absolute;
}
.advanced-cropper-line-wrapper__content--east,
.advanced-cropper-line-wrapper__content--west {
	height: 100%;
}
.advanced-cropper-line-wrapper__content--north,
.advanced-cropper-line-wrapper__content--south {
	width: 100%;
}
.advanced-cropper-line-wrapper__content--east {
	left: 50%;
	transform: translateX(-100%);
}
.advanced-cropper-line-wrapper__content--west {
	right: 50%;
	transform: translateX(100%);
}
.advanced-cropper-line-wrapper__content--north {
	top: 50%;
}
.advanced-cropper-line-wrapper__content--south {
	bottom: 50%;
}

.advanced-cropper-handler-wrapper {
	position: absolute;
	transform: translate(-50%, -50%);
	width: 30px;
	height: 30px;
}
.advanced-cropper-handler-wrapper__draggable {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}
.advanced-cropper-handler-wrapper--west-north {
	cursor: nw-resize;
}
.advanced-cropper-handler-wrapper--north {
	cursor: n-resize;
}
.advanced-cropper-handler-wrapper--east-north {
	cursor: ne-resize;
}
.advanced-cropper-handler-wrapper--east {
	cursor: e-resize;
}
.advanced-cropper-handler-wrapper--east-south {
	cursor: se-resize;
}
.advanced-cropper-handler-wrapper--south {
	cursor: s-resize;
}
.advanced-cropper-handler-wrapper--west-south {
	cursor: sw-resize;
}
.advanced-cropper-handler-wrapper--west {
	cursor: w-resize;
}
.advanced-cropper-handler-wrapper--disabled {
	cursor: auto;
}

.advanced-cropper-bounding-box {
	position: relative;
	height: 100%;
	width: 100%;
}
.advanced-cropper-bounding-box__handler-wrapper {
	position: absolute;
}
.advanced-cropper-bounding-box__handler-wrapper--east,
.advanced-cropper-bounding-box__handler-wrapper--west {
	top: 50%;
	transform: translateY(-50%);
	height: 100%;
}
.advanced-cropper-bounding-box__handler-wrapper--south,
.advanced-cropper-bounding-box__handler-wrapper--north {
	left: 50%;
	transform: translateX(-50%);
	width: 100%;
}
.advanced-cropper-bounding-box__handler-wrapper--west,
.advanced-cropper-bounding-box__handler-wrapper--west-north,
.advanced-cropper-bounding-box__handler-wrapper--west-south {
	left: 0;
}
.advanced-cropper-bounding-box__handler-wrapper--east,
.advanced-cropper-bounding-box__handler-wrapper--east-north,
.advanced-cropper-bounding-box__handler-wrapper--east-south {
	left: 100%;
}
.advanced-cropper-bounding-box__handler-wrapper--north,
.advanced-cropper-bounding-box__handler-wrapper--west-north,
.advanced-cropper-bounding-box__handler-wrapper--east-north {
	top: 0;
}
.advanced-cropper-bounding-box__handler-wrapper--south,
.advanced-cropper-bounding-box__handler-wrapper--west-south,
.advanced-cropper-bounding-box__handler-wrapper--east-south {
	top: 100%;
}
.advanced-cropper-bounding-box__handler {
	position: absolute;
}
.advanced-cropper-bounding-box__handler--west-north {
	left: 0;
	top: 0;
}
.advanced-cropper-bounding-box__handler--north {
	left: 50%;
	top: 0;
}
.advanced-cropper-bounding-box__handler--east-north {
	left: 100%;
	top: 0;
}
.advanced-cropper-bounding-box__handler--east {
	left: 100%;
	top: 50%;
}
.advanced-cropper-bounding-box__handler--east-south {
	left: 100%;
	top: 100%;
}
.advanced-cropper-bounding-box__handler--south {
	left: 50%;
	top: 100%;
}
.advanced-cropper-bounding-box__handler--west-south {
	left: 0;
	top: 100%;
}
.advanced-cropper-bounding-box__handler--west {
	left: 0;
	top: 50%;
}
.advanced-cropper-bounding-box__line {
	position: absolute;
}
.advanced-cropper-bounding-box__line--north,
.advanced-cropper-bounding-box__line--south {
	left: 0;
	transform: translateY(-50%);
}
.advanced-cropper-bounding-box__line--north {
	top: 0;
}
.advanced-cropper-bounding-box__line--south {
	top: 100%;
}
.advanced-cropper-bounding-box__line--west,
.advanced-cropper-bounding-box__line--east {
	top: 0;
	transform: translateX(-50%);
}
.advanced-cropper-bounding-box__line--west {
	left: 0;
}
.advanced-cropper-bounding-box__line--east {
	left: 100%;
}

.advanced-cropper-artificial-transition {
	will-change: transform;
}

.advanced-cropper-background-image {
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	position: absolute;
	transform-origin: center;
	pointer-events: none;
	max-width: none !important;
}

.advanced-cropper-canvas {
	display: none;
}

.advanced-cropper-source {
	width: 1px;
	height: 1px;
	visibility: hidden;
	position: absolute;
	opacity: 0;
}

.advanced-cropper-fade {
	visibility: hidden;
	opacity: 0;
	transition: 0.5s;
	display: flex;
	flex-direction: column;
}
.advanced-cropper-fade--visible {
	opacity: 1;
	visibility: visible;
}

.advanced-cropper-wrapper__fade {
	flex-grow: 1;
	min-height: 0;
}

.advanced-cropper-stencil-grid {
	display: table;
	border-collapse: collapse;
	table-layout: fixed;
	opacity: 0;
	transition: opacity 0.3s;
}
.advanced-cropper-stencil-grid--visible {
	opacity: 1;
}
.advanced-cropper-stencil-grid__row {
	display: table-row;
}
.advanced-cropper-stencil-grid__cell {
	display: table-cell;
	width: 1%;
	height: 1%;
	border: currentColor solid 1px;
}
.advanced-cropper-stencil-grid__cell--top {
	border-top-color: transparent;
}
.advanced-cropper-stencil-grid__cell--left {
	border-left-color: transparent;
}
.advanced-cropper-stencil-grid__cell--right {
	border-right-color: transparent;
}
.advanced-cropper-stencil-grid__cell--bottom {
	border-bottom-color: transparent;
}

.advanced-cropper-stencil-overlay {
	box-sizing: content-box;
	box-shadow: 0 0 0 1000px currentColor;
	pointer-events: none;
	width: 100%;
	height: 100%;
	position: absolute;
	-webkit-backface-visibility: hidden;
}

.advanced-cropper-stencil-wrapper {
	will-change: transform;
}

.advanced-cropper-boundary {
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	direction: ltr;
	position: relative;
}
.advanced-cropper-boundary__content {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
}
.advanced-cropper-boundary__stretcher {
	pointer-events: none;
	position: relative;
	max-width: 100%;
	max-height: 100%;
}

.advanced-cropper-circle-stencil {
	position: absolute;
	height: 100%;
	width: 100%;
	box-sizing: content-box;
	-webkit-backface-visibility: hidden;
	-webkit-transform-style: preserve-3d;
}
.advanced-cropper-circle-stencil__overlay {
	border-radius: 50%;
	overflow: hidden;
}
.advanced-cropper-circle-stencil__preview {
	border-radius: 50%;
}
.advanced-cropper-circle-stencil__draggable-area,
.advanced-cropper-circle-stencil__overlay,
.advanced-cropper-circle-stencil__preview,
.advanced-cropper-circle-stencil__grid {
	position: absolute;
	height: 100%;
	width: 100%;
}
.advanced-cropper-circle-stencil--movable {
	cursor: move;
}

.advanced-cropper-rectangle-stencil {
	position: absolute;
	height: 100%;
	width: 100%;
	box-sizing: border-box;
	-webkit-backface-visibility: hidden;
	-webkit-transform-style: preserve-3d;
}
.advanced-cropper-rectangle-stencil__draggable-area,
.advanced-cropper-rectangle-stencil__overlay,
.advanced-cropper-rectangle-stencil__preview,
.advanced-cropper-rectangle-stencil__grid {
	position: absolute;
	height: 100%;
	width: 100%;
}
.advanced-cropper-rectangle-stencil--movable {
	cursor: move;
}

.advanced-cropper-simple-line {
	background: none;
	border-width: 0;
}
.advanced-cropper-simple-line--south,
.advanced-cropper-simple-line--north {
	height: 0;
	width: 100%;
}
.advanced-cropper-simple-line--east,
.advanced-cropper-simple-line--west {
	height: 100%;
	width: 0;
}
.advanced-cropper-simple-line--east {
	border-right-width: 1px;
}
.advanced-cropper-simple-line--west {
	border-left-width: 1px;
}
.advanced-cropper-simple-line--south {
	border-bottom-width: 1px;
}
.advanced-cropper-simple-line--north {
	border-top-width: 1px;
}

.advanced-cropper-simple-handler {
	display: block;
}

.advanced-cropper-preview {
	overflow: hidden;
	box-sizing: border-box;
	position: relative;
	display: flex;
	flex-direction: column;
}
.advanced-cropper-preview__content {
	overflow: hidden;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}
.advanced-cropper-preview__image {
	display: none;
	pointer-events: none;
	position: absolute;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	transform-origin: center;
	max-width: none !important;
}
.advanced-cropper-preview__image--visible {
	display: block;
}
.advanced-cropper-preview__boundary {
	flex-grow: 1;
	min-height: 0;
	min-width: 0;
}

.cropper-preview-wrapper__fade {
	flex-grow: 1;
	min-height: 0;
	width: 100%;
}

.advanced-cropper {
	overflow: hidden;
	max-height: 100%;
	background: white;
	position: relative;
	display: flex;
	flex-direction: column;
	color: white;
}
.advanced-cropper__boundary {
	flex-grow: 1;
	min-height: 0;
	min-width: 0;
}
.advanced-cropper__wrapper,
.advanced-cropper__background-wrapper {
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	position: absolute;
}
.advanced-cropper__stencil-wrapper {
	position: absolute;
}
.advanced-cropper * {
	box-sizing: border-box;
}

.advanced-cropper-simple-handler {
	background: currentColor;
	height: 10px;
	width: 10px;
}

.advanced-cropper-simple-line {
	transition: border 0.5s;
	border-color: rgba(255, 255, 255, 0.3);
	border-style: solid;
}
.advanced-cropper-simple-line--hover {
	border-color: white;
}

.advanced-cropper-circle-stencil__preview {
	border: solid 2px rgba(255, 255, 255, 0.2);
}

.advanced-cropper-stencil-overlay {
	color: rgba(0, 0, 0, 0.5);
}

.advanced-cropper-stencil-grid {
	color: rgba(255, 255, 255, 0.4);
}
