.AccordionRoot {
	@apply w-full;
}

.AccordionItem {
	@apply overflow-hidden;
}

.AccordionItem:focus-within {
	@apply relative z-[1];
}

.AccordionHeader {
	@apply flex;
}

.AccordionContent[data-state='open'] {
	animation: slideDown 200ms cubic-bezier(0.87, 0, 0.13, 1);
}
.AccordionContent[data-state='closed'] {
	animation: slideUp 200ms cubic-bezier(0.87, 0, 0.13, 1);
}

.AccordionChevron {
	transition: transform 200ms cubic-bezier(0.87, 0, 0.13, 1);
}

.AccordionTrigger[data-state='open'] > .AccordionChevron {
	transform: rotate(180deg);
}

@keyframes slideDown {
	from {
		height: 0;
	}
	to {
		height: var(--radix-accordion-content-height);
	}
}

@keyframes slideUp {
	from {
		height: var(--radix-accordion-content-height);
	}
	to {
		height: 0;
	}
}
