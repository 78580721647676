.custom-styles {
	--ReactInputVerificationCode-itemWidth: 2rem;
	--ReactInputVerificationCode-itemHeight: 3rem;
}

.custom-styles .ReactInputVerificationCode__item {
	@apply relative font-bold text-neutral-0;
}

.custom-styles .ReactInputVerificationCode__item,
.custom-styles .ReactInputVerificationCode__item.is-active {
	@apply shadow-none;
}

.custom-styles .ReactInputVerificationCode__item:after {
	@apply absolute bottom-0 left-0 right-0 h-0.5 bg-neutral-700 transition-colors duration-200 ease-out;
	content: '';
}

.custom-styles .ReactInputVerificationCode__item.is-active:after {
	@apply bg-blue;
}
